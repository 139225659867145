.url-output-box {
    margin-top: 2rem;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    min-height: 100px;
}

.footer {
    min-height: 5rem;
    margin-top: 2rem;
}